import {LitElement, html, css} from 'lit';
import '@material/mwc-button';
import {status} from '../elements/statuses';
import {sharedStyles} from '../shared-styles';
import {datadogRum} from '@datadog/browser-rum';
import '../elements/upload-item';

/**
 * `price-discount-page`
 *  Display the uploader for product price discounts files
 */
export class PriceDiscountPage extends LitElement {
  /**
     * Defines the elements styles
     *
     * @return {CSSResult} the resulting styles
     */
  static get styles() {
    const style = css`
            small {
                display: block;
                margin: 10px;
            }

            small > a {
                display: block;
            }

            input[type='datetime-local'] {
                width: 25%;
            }
      
            /*Some elements need to be disabled for the TPP work to go live*/
            .temp-hide {
              display: none;
            }
        `;
    return [sharedStyles, style];
  }

  /**
     * Defined the elements content
     *
     * @return {TemplateResult} the resulting html template
     */
  render() {
    return html`
            <h2 class="page-heading">Price Discount Uploader</h2>
            <div class="page-body">
                <form>
                    <label class="temp-hide">
                        Would you like to schedule when these discounts go live?
                        (optional)
                        <input
                            name="scheduledTime"
                            type="datetime-local"
                            min=${PriceDiscountPage.minScheduledTime}
                        />
                    </label>
                    <label>
                        Upload a Price Discount CSV file
                        <input
                            name="upload"
                            type="file"
                            accept=".csv"
                            required
                        />
                    </label>
                    <small
                        >The CSV file is expected in a specific format.
                        <a
                            class="temp-hide"
                            href="/templates/international-price-discounts.csv"
                            download="price-discounts.csv"
                            >Download the International template here.</a
                        >
                        <a
                            class="temp-hide"
                            href="/templates/north-america-price-discounts.csv"
                            download="price-discounts.csv"
                            >Download the North America template here.</a
                        >
                        <a href="/templates/retail-price-discounts.csv" download="price-discounts.csv">
                          Download the template for retail store price updates
                        </a>
                      
                        <ul>
                          <li>
                            If you do not wish to discount a store, then make sure to 
                            remove the entire column representing the store
                          </li>
                          <li>You need as a minimum one UNCLE SKU to update all linked variants</li>
                          <li>Values must not include any currency or percentage symbols</li>
                          <li>The value is the discounted price of product i.e. the target price after markdown</li>
                          <li>
                            A successful upload will simply disappear – you will not receive a confirmation, 
                            but unless the UI displays an error, you upload is successful.
                          </li>
                        </ul>
                    </small>
                    <mwc-button
                        class="submit"
                        raised
                        @click=${this.handleUpload}
                    >
                        Upload
                    </mwc-button>
                    <mwc-button class="cancel" @click=${this.resetForm}>
                        Clear
                    </mwc-button>
                    <p class="error" ?hidden=${this.state !== status.Errored}>
                        Oops there has been an error uploading.
                        ${this.error?.error || this.error}
                    </p>
                    <p class="saving" ?hidden=${this.state !== status.Saving}>Uploading file.</p>
                </form>
                <h2>Discount Uploads History</h2>
                <mwc-button
                    class="view"
                    raised
                    @click=${this.getDiscountUploads}
                >
                    View Uploads
                </mwc-button>
                <div class="uploads">
                    ${this.discountUploads.map(
      (upload) =>
        html`<upload-item
                                .service=${this.service}
                                .upload=${upload}
                            ></upload-item>`,
  )}
                </div>
                <p class="error" ?hidden=${this.state !== status.Errored}>
                    There has been an error getting the uploads.
                    ${this.error?.error || this.error}
                </p>
                <p class="loading" ?hidden=${this.state !== status.Loading}>
                    Loading uploads.
                </p>
            </div>
        `;
  }

  /**
     * Defines the elements properties
     *
     * @return {object} the props
     */
  static get properties() {
    return {
      /** The data parsed from the route url */
      routeData: {type: Object},
      /** The service for getting data */
      service: {type: Object},
      /** What state we are in */
      state: {type: String},
      /** Any error from the API */
      error: {type: Object},
      /** The list of discount upload files */
      discountUploads: {type: Array},
    };
  }

  /** Initialises values of properties */
  constructor() {
    super();
    this.routeData = {};
    this.service = {};
    this.state = status.Idle;
    this.error = {};
    this.discountUploads = [];
  }

  /**
     * Helper to get the form element
     * @return {HTMLElement} the form element
     */
  get form() {
    return this.shadowRoot.querySelector('form');
  }

  /**
     * Wrapper to reset the form and state
     */
  resetForm() {
    this.form.reset();
    this.state = status.Idle;
  }

  /**
     * Retrieves price discount uploads
     */
  async getDiscountUploads() {
    try {
      this.state = status.Loading;
      let uploads = await this.service.send('/uploads/discounts', 'GET');
      uploads = uploads.sort((a, b) => {
        return new Date(b.uploadDate) - new Date(a.uploadDate);
      });
      this.discountUploads = uploads;
      this.state = status.Loaded;
    } catch (e) {
      this.state = status.Errored;
      this.error = e;
      datadogRum.addError(e, {source: 'price-discount-page'});
    }
  }

  /**
     * Handles the submission of the form, getting the data from the form and sending it to the service
     *
     * If a scheduled time is set, it is converted to ISO format before sending so we don't need to deal with timezones
     *
     * @param {Event} event the submission event
     */
  async handleUpload(event) {
    event.preventDefault();
    try {
      this.state = status.Saving;
      const valid = this.form.reportValidity();
      if (!valid) {
        return;
      }

      const formdata = this.getFormDataWithConvertedTime();
      await this.service.send('/uploads/discounts', 'POST', formdata);
      this.state = status.Idle;
    } catch (e) {
      this.state = status.Errored;
      this.error = e;
      datadogRum.addError(e, {source: 'price-discount-page'});
    }
  }

  /**
     * Converts the scheduled time to ISO format and get the formdata
     *
     * @return {FormData} the form data with the scheduled time converted to ISO format
     */
  getFormDataWithConvertedTime() {
    const formdata = new FormData(this.form);

    const scheduledTime = formdata.get('scheduledTime');
    if (scheduledTime) {
      formdata.set(
          'scheduledTime',
          new Date(scheduledTime).toISOString(),
      );
    }

    formdata.set('discountTemplateType', 'fixed-price-discount');

    return formdata;
  }

  /**
     * Gets the minimum scheduled time, 30 minutes from now
     *
     * @return {string} the minimum scheduled time
     */
  static get minScheduledTime() {
    const now = new Date();
    now.setMinutes(now.getMinutes() + 30);
    const time = now.toLocaleTimeString('en-GB').substring(0, 5);
    const date = now
        .toLocaleDateString('en-GB')
        .split('/')
        .reverse()
        .join('-');
    return `${date}T${time}`;
  }
}
window.customElements.define('price-discount-page', PriceDiscountPage);
