import {LitElement, html, css} from 'lit';
import router from 'page/page.mjs';
import {createAuth0Client} from '@auth0/auth0-spa-js';
import {datadogRum} from '@datadog/browser-rum';
import {parseQueryParams, validatePage, importPage} from './router';
import {sharedStyles} from './shared-styles';
import {status} from './elements/statuses';
import Service from './elements/service';
import 'wc-spinners/dist/hollow-dots-spinner';
import '@material/mwc-top-app-bar-fixed';
import '@material/mwc-button';

/**
 * `app-shell`
 *  Shell to run the whole app
 */
export class AppShell extends LitElement {
  /**
   * Defines the elements styles
   *
   * @return {CSSResult} the resulting styles
   */
  static get styles() {
    const style = css`
      :host {
        display: block;
        --mdc-theme-primary: black;
        --mdc-shape-small: 24px;
        --mdc-typography-button-font-family: 'montserrat';
        --mdc-typography-button-font-weight: 700;
        --mdc-typography-button-letter-spacing: 0;
        --mdc-button-horizontal-padding: 32px;
        --mdc-button-vertical-padding: 24px;
        --md-linear-progress-active-indicator-color: var(--mdc-theme-primary);
      }

      a[slot='title'] {
        color: var(--white);
        text-decoration: none;
      }

      main {
        padding: 24px;
        padding-left: 284px;
      }

      main.loading,
      main.error,
      main.unauthenticated {
        display: flex;
        height: 25vh;
      }

      main.loading > hollow-dots-spinner {
        margin: auto;
        --hollow-dots-spinner__color: var(--mdc-theme-primary);
      }

      main.error > p.error {
        margin: auto;
      }

      main.unauthenticated {
        flex-direction: column;
      }

      main.unauthenticated > h3 {
        margin: auto auto 0 auto;
      }

      main.unauthenticated > mwc-button {
        width: 50vw;
        margin: auto;
      }

      .menu-left {
        background: rgb(242, 236, 238);
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 260px;
        padding: 24px;
        display: grid;
        grid-template-rows: 35px 1fr 30px;
        box-sizing: border-box;

        button {
          padding: 8px 16px;
          border: none;
          background: none;
          font-size: 16px;
          color: var(--black);
          display: flex;
          gap: 8px;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }

      .links {
        list-style-type: none;
        padding: 0;
        margin: 16px 0 0;

        .menu-item {
          border-radius: 4px;

          &.disabled {
            cursor: not-allowed !important;
            a {
                color: grey;
                cursor: not-allowed !important;

                &:hover {
                    cursor: not-allowed !important;
                    background: rgba(200, 200, 200, 0.5);
                }
            }
          }
        }

        .menu-item:hover {
          background: rgb(220 218 245 / 50%);
        }

        a {
          padding: 8px 16px;
          text-decoration: none;
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }

      .link-active {
        background: rgb(220, 218, 245);
      }

      img {
        width: 24px;
      }

      .logo {
        font-weight: 400;
        text-transform: uppercase;
        font-family: "Bebas Neue", serif;
        letter-spacing: 2px;
        font-size: 24px;
        text-decoration: none;
      }
    `;

    return [sharedStyles, style];
  }

  /**
   * Defined the navbar content
   *
   * @return {TemplateResult} the resulting html template
   */
  get nav() {
    if (!this.isAuthenticated) {
      return html`<button class="login" @click=${this.login}>
        Log in <span class="material-icons">login</span>
      </button>`;
    }

    return html`<button class="logout" @click=${this.logout}>
      Log out <span class="material-icons">logout</span>
    </button>`;
  }

  /**
   * Defined the header content
   *
   * @return {TemplateResult} the resulting html template
   */
  get header() {
    if (!this.isAuthenticated) {
      return html`
        <div class="menu-left">
          <a href="/" class="logo">
            <img
              src="https://cdn.gymshark.com/images/branding/gs-icon-black.svg"
            />
            pricing tool
          </a>
        </div>
      `;
    }

    return html`
      <div class="menu-left">
        <a href="/" class="logo">
          <img src="https://cdn.gymshark.com/images/branding/gs-icon-black.svg"/>
          pricing tool
        </a>
        <ul class="links">
          <li class="disabled menu-item ${this.page === 'price-updates' && 'link-active'}">
            <!-- Disabled for TPP go-live [PP-2352] (href="/price-updates" -->
            <a href="#">
              <span class="material-icons">paid</span>
              Update Prices
            </a>
          </li>
          <li class="menu-item ${this.page === 'price-discounts' && 'link-active'}">
            <a href="/price-discounts">
              <span class="material-icons">sell</span>
              Price Discounts
            </a>
          </li>
          <li class="disabled menu-item ${this.page === 'schedule-management' && 'link-active'}">
            <!-- Disabled for TPP go-live [PP-2352] (href="/schedule-management" -->
            <a href="/#">
              <span class="material-icons">edit_calendar</span>
              Manage Schedules
            </a>
          </li>
          <li class="menu-item ${this.page === 'intake-rrp-conversion' && 'link-active'}">
            <a href="/intake-rrp-conversion">
              <span class="material-icons">shopping_cart_checkout</span>
              Intake RRP
            </a>
          </li>
        </ul>
        <nav slot="actionItems">
          <button class="logout" @click=${this.logout}>
            <span class="material-icons">logout</span>
            Log out
          </button>
        </nav>
      </div>
    `;
  }

  /**
   * Defined the elements content
   *
   * @return {TemplateResult} the resulting html template
   */
  render() {
    if (this.state === status.Errored) {
      const content = html` <main class="error">
        <p class="error">
          Oops there has been an error. <a href="/">Go home</a>
        </p>
      </main>`;
      return [this.header, content];
    }

    if (this.state === status.Loading) {
      const content = html` <main class="loading">
        <hollow-dots-spinner></hollow-dots-spinner>
      </main>`;
      return [this.header, content];
    }

    if (!this.isAuthenticated) {
      const content = html`
        <main class="unauthenticated">
          <h3>You are required to login to access product uploads.</h3>
          <mwc-button class="login" raised @click=${this.login}
            >Login</mwc-button
          >
        </main>
      `;
      return [this.header, content];
    }

    const content = html` <main class="authenticated">
      <home-page ?hidden=${this.page !== 'home'}></home-page>

      <intake-rrp-conversion-page
        ?hidden=${this.page !== 'intake-rrp-conversion'}
        .routeData=${this.routeData}
        .service=${this.service}
      ></intake-rrp-conversion-page>

      <price-updates-page
        ?hidden=${this.page !== 'price-updates'}
        .routeData=${this.routeData}
        .service=${this.service}
      ></price-updates-page>

      <price-discount-page
        ?hidden=${this.page !== 'price-discounts'}
        .routeData=${this.routeData}
        .service=${this.service}
      ></price-discount-page>

      <schedule-management-page
        ?hidden=${this.page !== 'schedule-management'}
        .routeData=${this.routeData}
        .service=${this.service}
      ></schedule-management-page>

      <error-page ?hidden=${this.page !== 'error'}> </error-page>
    </main>`;
    return [this.header, content];
  }

  /**
   * Defines the elements properties
   *
   * @return {object} the props
   */
  static get properties() {
    return {
      /** The page to display */
      page: {type: String, reflect: true},
      /** The data parsed from the route url */
      routeData: {type: Object},
      /** The query params from the route url */
      queryParams: {type: Object},
      /** The auth client */
      auth: {type: Object},
      /** The http client */
      service: {type: Object},
      /** What state we are in */
      state: {type: String},
      /** If the user is authenticated */
      isAuthenticated: {type: Boolean},
    };
  }

  /** Initialises values of properties */
  constructor() {
    super();
    this.routeData = {
      params: {},
    };
    this.queryParams = {};
    this.state = status.Loading;
    this.isAuthenticated = false;
    this.service = {};
    this.routing();
    AppShell.startMonitoring();
  }

  /** Add any event listeners */
  async connectedCallback() {
    if (super.connectedCallback) {
      super.connectedCallback();
    }
    this.auth = await createAuth0Client({
      domain: AUTH_DOMAIN,
      clientId: AUTH_CLIENT_ID,
      authorizationParams: {
        audience: AUDIENCE,
        redirect_uri: window.location.origin,
        scope: 'profile email price:admin',
      },
    });
    this.service = new Service(this.auth, API_URL);

    const query = window.location.search;
    if (query.includes('code=') && query.includes('state=')) {
      await this.auth.handleRedirectCallback();
      window.history.replaceState({}, document.title, '/');
      this.isAuthenticated = await this.checkAuthenticatedStatus();
    }
  }

  /** Client side routing */
  routing() {
    // Parses off any query strings from the url and sets a query string object
    // url ?hi=everyone
    // queryParams {hi: 'everyone'}
    router('*', (context, next) => {
      this.queryParams = parseQueryParams(context);
      next();
    });
    // Browsing to / takes you to channels
    router('/', (context) => {
      const {...routeData} = context;
      routeData.params.page = 'home';
      this.routeData = routeData;
    });
    // Browsing to /home tries to take you to that page
    router('/:page', (context) => {
      this.routeData = context;
    });
    router();
  }

  /**
   * Fired whenever any property changes and the template updates
   *
   * @param {PropertyValues} changedProperties map of changed properties
   */
  async updated(changedProperties) {
    if (super.updated) {
      super.updated();
    }

    if (
      (changedProperties.has('routeData') || changedProperties.has('auth')) &&
      this.routeData?.params?.page &&
      this.auth?.isAuthenticated
    ) {
      this.isAuthenticated = await this.checkAuthenticatedStatus();
      this.page = validatePage(this.routeData.params.page);
    }
    if (changedProperties.has('page')) {
      importPage(this.page);
    }
  }

  /**
   * Call to check if the user is authenticated
   */
  async checkAuthenticatedStatus() {
    this.state = status.Loading;
    try {
      const isAuthenticated = await this.auth.isAuthenticated();
      this.state = status.Loaded;
      return isAuthenticated;
    } catch (e) {
      this.state = status.Errored;
    }
  }

  /**
   * Login the user
   */
  async login() {
    this.auth.loginWithRedirect();
  }

  /**
   * Log out the user
   */
  async logout() {
    this.auth.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
    router.show('/');
  }

  /**
   * Create a datadog monitoring session
   */
  static startMonitoring() {
    datadogRum.init({
      applicationId: DATADOG_APPLICATION_ID,
      clientToken: DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.eu',
      service: 'product-service-ui',
      env: ENVIRONMENT,
      version: SERVICE_VERSION,
      sampleRate: 100,
      trackInteractions: true,
      // is an internal application with no user facing data. Disable masking of text
      // to make debugging easier
      defaultPrivacyLevel: 'allow',
    });

    datadogRum.startSessionReplayRecording();
  }
}

window.customElements.define('app-shell', AppShell);
